import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { saveComment, resetCommentState } from '../../store/feature/commentSlice';
import './ProjectDetails.css';
import AppBar from './Appbar';
import { AppDispatch } from '../../store';
import Footer from './Footer';
import Swal from 'sweetalert2'; // SweetAlert2'yi içeri aktarın

const ProjectDetails = () => {
  const { title } = useParams<{ title: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { loading, success, error } = useSelector((state: any) => state.comments);
  const projects = useSelector((state: any) => state.projects.projects);

  const [commentData, setCommentData] = useState({
    projectId: '',
    companyName: '',
    name: '',
    surname: '',
    email: '',
    comment: '',
  });

  const project = projects.find(
    (proj: any) => proj.title === decodeURIComponent(title || '')
  );

  useEffect(() => {
    if (project) {
      setCommentData((prev) => ({ ...prev, projectId: project.id }));
    }
  }, [project]);

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: 'success',
        title: 'Yorum başarıyla kaydedildi!',
        text: 'Yorumunuz başarıyla gönderildi.',
        timer: 3000, // Close the alert after 3 seconds
        showConfirmButton: false, // Hide the confirm button
      });

      // Yorum verilerini sıfırla
      setCommentData({
        projectId: project?.id || '',
        companyName: '',
        name: '',
        surname: '',
        email: '',
        comment: '',
      });
      dispatch(resetCommentState());
    }
  }, [success, dispatch, project]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCommentData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(saveComment(commentData));
  };

  if (!project) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <AppBar />
        <p>Proje bulunamadı.</p>
      </div>
    );
  }

  return (
    <div>
      <AppBar />
      <div className="project-details">
        <h2>{project.title}</h2>

        {/* Açıklamayı HTML olarak render et */}
        <div
          dangerouslySetInnerHTML={{ __html: project.description }}
          className="project-description"
        />

        {/* Yorum Formu 
        <div className="comment-form">
          <h3>Yorum Yap</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="companyName"
              placeholder="Şirket Adı"
              value={commentData.companyName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="name"
              placeholder="Adınız"
              value={commentData.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="surname"
              placeholder="Soyadınız"
              value={commentData.surname}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="E-posta"
              value={commentData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="comment"
              placeholder="Yorumunuz"
              value={commentData.comment}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" disabled={loading}>
              {loading ? 'Gönderiliyor...' : 'Gönder'}
            </button>
          </form>
          {error && <p className="error">{error}</p>}
        </div>
        */}
      </div>
      <Footer />
    </div>
  );
};

export default ProjectDetails;
